.log-row {
	width: calc(100% - 60px);
	height: 70px;
	margin: auto;
	margin-top: 25px;

	background: var(--white);

	border: 3.5px solid transparent;
	border-radius: 5px;

	text-align: center;
	line-height: 40px;

	cursor: pointer;
	position: relative;
/*	transition: .2s;*/

	z-index: 10;
}

.log-row div img {
	width: 35px;
	height: 35px;
}

.log-row:hover .log-row-values > div:before {
	color: var(--grey-text);
	font-weight: bold;
	text-align: center;
	line-height: 15px;
	padding-top: 10px;
	display: block;
}
.log-row:hover .log-row-values > div:nth-child(1):before { content: 'Operation'; }
.log-row:hover .log-row-values > div:nth-child(2):before { content: 'Booking ID'; }
.log-row:hover .log-row-values > div:nth-child(3):before { content: 'User'; }
.log-row:hover .log-row-values > div:nth-child(4):before { content: 'Date'; }

.log-row:hover {
	line-height: 20px;
}
