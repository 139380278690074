#log-in-container {
	width: 350px;
	height: 450px;
	margin: auto;
	margin-top: calc(50vh - 225px);
	padding: 15px;

	background-color: var(--white);

	border-radius: 10px;

	box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);

	text-align: center;
	font-size: 20px;
}

#log-in-container form {
	height: 100%;
}

input {
	margin: auto;
}
input[type="text"], input[type="password"] {
	width: 70%;
}