.bookingRow {
	width: calc(100% - 60px);
	height: 85px;
	margin: auto;
	margin-top: 25px;

	background: var(--white);

	border: 3.5px solid transparent;
	border-radius: 5px;

	cursor: pointer;
	transition: .2s;

	position: relative;
}
.bookingRow.selected {
	border: 3.5px solid rgba(var(--deep-blue));
	box-shadow: 1px 1px 3px rgba(45, 125, 255, .5);
}
.bookingRow * { margin: auto 0; text-align: center; }
.bookingRow div { width: calc(100% / 8); }

.bookingRow .user-name {
	top: -20px;
	left: -20px;
	width: 40px;
	height: 40px;

	background-color: var(--white);

	border-radius: 40px;
	border: 3px solid rgba(var(--deep-blue), .3);

	text-align: center;
	line-height: 40px;

	color: rgba(var(--deep-blue), .5);

	position: absolute;

	transition: .2s;
}
.bookingRow.selected .user-name {
	border: 3px solid rgba(var(--deep-blue));
	color: rgba(var(--deep-blue));
}

.bookingRow:hover > div:before {
	width: fit-content;
	color: var(--grey-text);
	font-weight: bold;
	text-align: center;
	padding-bottom: 5px;
	display: block;
}
.bookingRow:hover > div:nth-child(2):before { content: 'Last & Firstname'; }
.bookingRow:hover > div:nth-child(3):before { content: 'POS'; }
.bookingRow:hover > div:nth-child(4):before { content: 'Date & Time'; }
.bookingRow:hover > div:nth-child(5):before { content: 'Coverts'; }
.bookingRow:hover > div:nth-child(6):before { content: 'Int. / Ext.'; }
.bookingRow:hover > div:nth-child(7):before { content: 'Contact / Room num'; }
.bookingRow:hover > div:nth-child(8):before { content: 'Notes'; }


.bookingRow_id {
	max-width: 75px;
	height: 75px;
	margin: 5px;

	background: rgba(var(--deep-blue), .3);

	border-radius: 5px;
}
.bookingRow_id h3 {
	max-width: 75px;
	margin: 0;

	color: rgba(var(--deep-blue));
	font-size: 30px;
	text-align: center;
	line-height: 75px;
}

.bookingRow_state {
	min-width: 200px;
}
.bookingRow_state button {
	width: 75px;
	height: 30px;
	margin: 5px;

	background-color: transparent;
	border: 1.5px solid;
	border-radius: 5px;

	cursor: pointer;
	transition: .2s
}
.bookingRow_state > button:nth-child(1) {
	border-color: #00B000;
	color: #00B000;
}
.bookingRow_state > button:nth-child(2) {
	border-color: darkorange;
	color: darkorange;
}
.bookingRow_state > button:nth-child(3) {
	width: 90px;

	border-color: red;
	color: red;
}

#searchbar > * {
	height: 35px;
	padding: 5px;

	background-color: var(--white);

	border: none;
	border-radius: 5px;

	box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
}

#searchbar > *:disabled {
	background-color: #F4F4F4;
	cursor: wait;
}

#searchbar select {
	max-width: 125px;

	outline: transparent;	
}
#searchbar input[type="search"] {
	min-width: 55%;
	max-width: 55%;

	font-size: 17px;

	outline: transparent;
}
#searchbar button {
	min-width: 100px;
	max-width: 100px;

	line-height: 25px;
	font-weight: bold;

	cursor: pointer;
}
#searchbar button img {
	width: 20px;
	height: 20px;
	vertical-align: middle;

	animation: rotate infinite 1.5s;
}

button#addBooking_button {
	width: 100px;
	height: 40px;
	padding: 5px;
	margin-top: 30px;

	background-color: var(--white);

	border: none;
	border-radius: 5px;

	box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);

	font-size: 16px;
	line-height: 25px;
	font-weight: bold;
	color: #2E2E2E;

	cursor: pointer;
}

button#showMoreBookings {
	margin: auto;
	
	display: block;

	cursor: pointer;
}

/* Aside */
#aside h1 {
	text-align: center;
}
#aside #bookingForm select {
	width: 90%;
	margin: 10px auto;

	padding: 1px 2px;
}
#aside #bookingForm input[type="time"], #aside #bookingForm input[type="date"] {
	width: 39%;
	margin: 10px auto;
}
#aside #bookingForm input[type="text"] {
	width: 90%;
	margin: 10px auto;
}
#aside #bookingForm input[name="coverts"] {
	width: 39%;
	margin: 10px auto;
}
#aside #bookingForm select[name="intExt"] {
	width: 39%;
	height: 41px;
	margin: 10px auto;
}
#aside textarea {
	width: 90%;
	height: 100px;
	margin: 10px auto;

	font-size: 15px;
	font-family: Arial;

	resize: none;
}

#aside input:focus, #aside select:focus, #aside textarea:focus {
	border: 2px solid rgba(var(--deep-blue), 1);

	box-shadow: 2px 2px 3px rgba(45, 125, 255, .2);
}

#aside input:invalid, #aside select:invalid, #aside textarea:invalid {
	border: 2px solid red;

	box-shadow: 2px 2px 3px rgba(255, 0, 0, .2);
}
#aside input[aria-invalid="true"], #aside select[aria-invalid="true"], #aside textarea[aria-invalid="true"] {
	border: 2px solid red;

	box-shadow: 2px 2px 3px rgba(255, 0, 0, .2);
}
/* --- Aside --- */

/* CSC */
#csc_container {
	width: 255px;
	min-height: 330px;
	max-height: 330px;

	background-color: var(--white);

	border-radius: 10px;
	border: 3px solid var(--white);
	
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

	position: absolute;
}
#csc_container h3 {
	height: 20px;
	margin: 10px auto;
	text-align: center;
}
#csc {
	transition: .5s;
	height: 430px;
}
#csc div.csc_stats {
	width: 100%;
	height: 200px;
	margin: 0 auto;

	display: block;
}
#csc > div.csc_stats > div.doughnut > canvas {
	max-width: 200px;
	min-width: 200px;
	max-height: 200px;
	min-height: 200px;

	margin: 0 auto;
}

#csc > div.csc_stats > div.linebar > canvas {
	max-width: 215px;
	min-width: 215px;
	max-height: 15px;
	min-height: 15px;

	margin: 0 auto;
}

#csc .csc_info div {
	margin: 5px 0;
}
#csc .csc_info {
	max-width: 240px;
	min-width: 240px;
	margin: 0 auto;

	display: block;
}
#csc .csc_info p {
	margin: 0 10px;

	text-align: left;
	font-size: 15px;

	line-height: 40px;
}

#csc .csc_info_value {
	width: 55px;
	height: 25px;

	border-radius: 5px;
	border: 2.5px solid lightgrey;

	text-align: center;
}
/* --- CSC --- */

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media (max-width: 1250px) {
	div#container .bookingRow {
		width: calc(100% - 30px);
	}
}

@media (max-width: 1150px) {
	div#container .bookingRow {
		height: 80px;
	}
	div#container .bookingRow_id {
		max-width: 70px;
		min-width: 70px;
		height: 70px;
	}
	div#container .bookingRow p, div#container .bookingRow button {
		font-size: 14px;
	}
	div#container .bookingRow .bookingRow_state {
		min-width: 150px;
		height: 75px;
	}
	div#container .bookingRow .bookingRow_state button {
		width: 60px;
	}
	div#container .bookingRow .bookingRow_state button:nth-child(3) {
		width: 80px;
	}
}

@media (max-width: 700px) {
	div#container .bookingRow {
		width: 95vw;
	}
	div#container .bookingRow_id {
		max-width: 60px;
		min-width: 60px;
		height: 60px;
		margin: auto;
	}
	div#container .bookingRow_id h3 {
		font-size: 25px;
		line-height: 65px;
	}
}
