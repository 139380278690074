:root {
	/* Sizes */
	--small-box-height: 300px;
	--large-box-height: 400px;

	--header-height: 45px;

	/* Colors */
	--table-subtitle-bgcolor: #F8F8F8;
	--table-subtitle-border: #D6D6D6;
}

#container {
	height: calc(100vh - 50px - 30px * 2);

	overflow: hidden auto;
}

#container > div > .settings-box {
	margin-top: 0;
	margin-bottom: 0;
}

#container .settings-box:nth-child(3) {
	margin-bottom: 0;
}

.settings-box {
	margin: 30px 0;

	background-color: var(--white);

	border-radius: 10px;

	overflow: hidden;
}
.container__small-boxes {
/*	min-width: calc(100vw - 400px - 30px);
	width: calc(100vw - 25vw - 30px);
	max-width: calc(100vw - 200px - 30px);*/
	width: 100%;
}
.settings-box-small {
	min-width: 47.5%;
	max-width: 47.5%;
	height: var(--small-box-height);
}
.settings-box-large {
/*	min-width: calc(100vw - 400px - 30px);*/
/*	width: calc(100vw - 25vw - 30px);*/
/*	max-width: calc(100vw - 200px - 30px);*/
	width: 100%;
	height: var(--large-box-height);
}

.settings-box__header {
	width: 100%;
	height: var(--header-height);
	margin: -10px 0 0 -5px;
	padding: 5px;

	background-color: var(--white);

	border: 1.5px solid lightgrey;
}
.settings-box__header .tabs {
	width: 50%;
	margin-bottom: -5px;
	margin-left: -2.5px;

	text-align: center;

	position: relative;
	cursor: pointer;
}
.settings-box__header select#setSettingNameDisplayed {
	margin: 10px -10% 0 0;
}

.settings-box-small .settings-box__content {
	max-height: calc(var(--small-box-height) - var(--header-height) - 5px);
	padding: 2px;

	overflow-y: scroll;
}
.settings-box-large .settings-box__content {
	max-height: calc(var(--large-box-height) - var(--header-height) - 5px);

	overflow: hidden auto;
}

/* POS box */
.settings-box__content .pos__section {
	width: 100%;
}

.settings-box__content input[type="text"], .settings-box__content input[type="number"] {
	height: 25px;
	margin: 5px 0;

	/*border: none;

	box-shadow: 3px 3px 5px #F0F0F0, -3px -3px 5px #FFF;*/

	display: block;
}

.settings-box__content .pos__section ul, .settings-box__content .pos__section li {
	width: 97.5% !important;
	margin-left: 0;
	padding-left: 0;
	list-style: none;
}
.settings-box__content .pos__section li {
	width: 300px;
	height: 40px;
}

.settings-box__content svg {
	margin: auto 10px auto 0;

	transform: rotateZ(90deg);

	cursor: grab;
	transition: .3s;
}

.settings-box__content .pos__section .pos__line {
	width: 100%;
	height: fit-content;
	margin: 10px;
	
	vertical-align: middle;

	transition: .3s;
}
.settings-box__content .pos__section .pos__line:hover svg {
	filter: opacity(.5);
}

.settings-box__content .pos__section .pos__line button, .settings-box__content .pos__section button {
	width: 100px;
	height: 30px;
	margin: 5px 5px;

	background-color: var(--table-subtitle-bgcolor);
	color: grey;

	border: none;
	border-radius: 20px;

	cursor: pointer;
	transition: .2s;
}
.settings-box__content .pos__section .pos__line button:hover, .settings-box__content .pos__section button:hover {
	background-color: #E2E2E2;
}
.settings-box__content .pos__section .pos__line button:active, .settings-box__content .pos__section button:hover {
	background-color: #D1D1D1;
}
.settings-box__content .pos__section button {
	margin: 10px;
	margin-bottom: 20px;
}

.settings-box__content .pos__section .pos__line span.checkbox-custom {
	margin: 7.5px 15px 0 0;
}

/* Aside POS box */
.POSAside__services-displayer {
	width: 60%;
	height: 70vh;
	margin: auto;
	padding-left: 2%;

	border: 2px var(--grey) solid;
	border-radius: 10px;

	display: grid; 
	grid-auto-columns: 1fr; 
	grid-template-columns: 98% 2%; 
	grid-template-rows: repeat(24, calc(70vh / 28) 3px); 
	gap: 0px 0px; 
	grid-template-areas: repeat(24, "services__devider services__hour" "services__box .");
}
.services__box {
	grid-aera: services__box;
}
.services__hour {
	margin-left: 10px;

	color: var(--text-light-grey);
	line-height: 55px !important;

	grid-aera: services__hour;
}
.services__devider {
	background-color: var(--grey);

	border-radius: 5px;

	grid-aera: services__devider;
}

.POSAside__services-form {
	margin: 20px auto;
}
.POSAside__services-form p {
	margin: 5px;

	font-size: 15px !important;
}
.POSAside__services-form input {
	width: 75px;
	height: 25px;
}
.services-button {
	height: 25px;
	background-color: var(--white);

	border: 2px solid #E0E0E0;
	border-radius: 5px;

	cursor: pointer;
}
/* --- Aside POS box --- */
/* --- POS box --- */

/* Confirm booking */
.booking-line {
	width: 100%;

	border-radius: 5px;
}
.booking-line:hover {
	background-color: rgba(0, 0, 0, .05);
}
.booking-line:last-child {
	margin-bottom: 7.5px;
}
.booking-line p {
	margin: auto inherit;
}
.booking-line-buttons {

}
.booking-line-buttons button {
	width: 30px;
	height: 30px;
	background-color: transparent;

	border: none;
	border-radius: 50%;

	vertical-align: middle;

	display: block;
	cursor: pointer;

	transition: .2s;
}
.booking-line-buttons button:hover {
	background-color: rgba(0, 0, 0, .15);
}
.booking-line-buttons button:active {
	background-color: rgba(0, 0, 0, .3);
}
.booking-line-buttons button img {
	margin: auto;
	display: block;
}
/* --- Confirm booking --- */

/* Rights box */
.settings-box__user-rights {
	color: var(--text-black);

	text-align: left;
}

.settings-box__user-rights table {
	border-collapse: collapse;
}
.settings-box__user-rights thead {
	height: 40px;

	font-size: 16px;

	text-align: center;
}
.settings-box__user-rights thead tr th:nth-child(1) {
	padding-left: 10px;
}
tr.settings-box__user-rights__subtitle {
	height: 30px;

	background-color: var(--table-subtitle-bgcolor);

	color: var(--text-light-grey);
}
tr.settings-box__user-rights__subtitle td {
	padding-left: 10px;

	font-weight: bold;
}
tr.settings-box__user-rights__sub-content {
	height: 35px;

	font-size: 16px;
}
tr.settings-box__user-rights__sub-content td {
	text-align: center;
}
tr.settings-box__user-rights__sub-content td:nth-child(1) {
	padding-left: 20px;

	text-align: left;
}

.settings-box__user-rights-inputs {
	width: 300px;
}
.settings-box__user-rights-inputs * {
	margin: 15px;
}
/* --- Rights box --- */

@media screen and (max-width: 950px) {
	.container__small-boxes {
		height: calc(var(--small-box-height)*2 + 30px);
		flex-direction: column;
	}

	.settings-box-small {
		min-width: calc(100vw - 400px - 30px);
		width: calc(100vw - 25vw - 30px);
		max-width: calc(100vw - 200px - 30px);
	}
}