:root {
	--background: #EFEFEF;
	--white: #FAFAFA;
	--grey: #EDEDED;
	--light-medium-grey: #CFCFCF;
	--medium-grey: #A2A2A2;
	--dark-grey: #3B3B3B;
	--selection-blue: 145, 200, 245; /* rgb(145, 200, 245) */
	--deep-blue: 45, 125, 255; /* rgb(45, 125, 255) */
	--shadow-select: 45, 125, 255, .5; /* rgba(45, 125, 255, .5) alpha: .5 recommended*/

	--error-background: #feefea;
	--error-color: #fb5f2d;

	--light-border: #DEDEDE;

	--grey-text: #BCBCBC;

	/* Text variable */
	--text-black: #121212;
	--text-grey: #3B3B3B;
	--text-light-grey: #A2A2A2;

	--text-light-blue: rgba(45, 125, 255, .3);

/*	color-scheme: dark;*/
}

/* Default classes */
.flex-row-around {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.flex-row-between {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-col {
	display: flex;
	flex-direction: column;
}
.flex-col-around {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.flex-col-between {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.flex-col-evenly {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}
.grid-center {
	display: grid;
	justify-content: center;
	align-items: center;
}
/* --- Default classes --- */

body {
	margin: 0;
	background: var(--background);

	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */

	font-family: Arial;

	overflow: hidden;
}

header {
	width: 100vw;
	height: 50px;

	background-color: var(--white);

	border-bottom: 1px solid var(--light-border);
	box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
}

h2 {
	color: var(--grey-text);
	text-align: center;
}
p {
	font-size: 17px;
}

#container {
	min-width: calc(100vw - 400px);
	width: calc(75vw);
	max-width: calc(100vw - 200px);

	padding: 30px;
}

/* Aside */
#aside #bookingForm input[type="submit"], #aside #bookingForm input[type="reset"] {
	margin-top: 10px;
}

/* aside error */
#aside #bookingForm > div > label.fieldError {
	text-align: center;
	font-size: 12px;
	font-style: italic;

	color: red;
}
/* --- Aside --- */

/* Header */
header .hamburgerMenu-icon {
	width: 25px;
	margin-left: 10px;

	opacity: 1;
	visibility: visible;
}
header .hamburgerMenu-icon:hover {
	cursor: pointer;
}
header ul.tabs {
	width: 75vw;
	height: 45px;
	margin-top: 0;
/*	margin: auto;*/

	list-style-type: none;

	line-height: 50px;
	font-size: 17px;
	font-weight: bold;

	transition: .2s;
}
header ul.tabs li {
	cursor: pointer;
	transition: .1s;
}
header ul.tabs li.selected {
	color: rgba(var(--deep-blue), 1);
	border-bottom: 3.5px solid rgba(var(--deep-blue), 1);
}
header .log-out-button {
	min-width: 100px;
	width: fit-content;
	height: fit-content;
	margin-block: auto;
	margin-right: 10px;

	padding: 5px;

	border: 3px solid rgba(var(--deep-blue), .7);
	border-radius: 7px;

	color: rgba(var(--deep-blue), .7);
	text-align: center;

	cursor: pointer;
}
/* --- Header --- */

/* Aside */
div#aside {
	min-width: 200px;
	width: 25vw;
	max-width: 400px;
	height: calc(100vh - 50px);

	background-color: var(--white);

	border-right: 1px solid var(--light-border);
}
div#aside div.logo {
	max-width: 100%;
	height: 300px;
	max-height: 300px;
	margin: 50px auto;
}
div#aside div.logo img {
	width: 100%;
	margin: auto;
	display: block;
}
div#aside p {
	color: var(--grey-text);
	text-align: center;
	font-size: 20px;
}
/* --- Aside --- */


/* Inputs and Fields */
*:disabled {
	cursor: not-allowed !important;
}

input, button, select, textarea { outline: none; }
input, button, select, textarea {
	width: 100px;
	height: 35px;

	background-color: var(--white);

	border: 2px solid #E0E0E0;
	border-radius: 5px;
}

input[type="submit"], button { cursor: pointer; }

input[type="checkbox"] {
	opacity: 0;
	display: none;
	position: absolute;
}
.checkbox-custom {
	width: 20px;
	height: 20px;
	vertical-align: middle;

	background-color: var(--white);

	border: 2.5px solid rgba(var(--deep-blue), .7);
	border-radius: 5px;

	cursor: pointer;

	display: inline-block;

	transition: .2s;
}
input[type="checkbox"]:disabled + .checkbox-custom {
	border: 2.5px solid rgba(20, 20, 20, .2);
}
input[type="checkbox"]:checked + .checkbox-custom {
	background-color: rgba(var(--deep-blue), .2);

	cursor: pointer;
}
input[type="checkbox"]:checked:disabled + .checkbox-custom {
	background-color: rgba(10, 10, 10, .2);

	border: 2.5px solid rgba(20, 20, 20, .2);
}
input[type="radio"] {
	opacity: 0;
	display: none;
	position: absolute;
}
.radio-custom {
	width: 20px;
	height: 20px;
	vertical-align: middle;

	background-color: var(--white);

	border: 2.5px solid rgba(var(--deep-blue), .7);
	border-radius: 100%;

	cursor: pointer;

	display: inline-block;

	transition: .2s;
}
input[type="radio"]:checked + .radio-custom {
	background-color: rgba(var(--deep-blue), .2);

	border: 2.5px solid rgba(var(--deep-blue), .7);

	cursor: pointer;
}
input[type="radio"]:disabled + .radio-custom {
	border: 2.5px solid rgba(20, 20, 20, .2);
}
input[type="radio"]:checked:disabled + .radio-custom {
	background-color: rgba(10, 10, 10, .2);

	border: 2.5px solid rgba(20, 20, 20, .2);
}

.separator {
	width: 90%;
	height: 2px;
	margin: 10px auto;

	background-color: #E0E0E0;

	border-radius: 1px;
}
/* --- Inputs and Fields --- */

/* Loader */
.loader {
	width: 400px;
	height: 100%;
	margin: auto;
}
.loader img {
	width: 100px;
	height: 100px;
	margin: auto;

	display: block;

	animation: rotate infinite 1.5s;
}

.loader h4 {
	text-align: center;

	color: rgb(115, 115, 115);
}
/* --- Loader --- */

/* Banner & Message box */
.error-banner {
	width: calc(100% - 30px);
	height: 55px;
	margin: 10px;
	padding: 10px;

	background-color: #FFF3CD;

	border-radius: 5px;

	box-shadow: 2px 2px 5px rgba(0, 0, 0, .1);
}

.error-banner__title {
	margin: 0 0 5px 0;

	font-size: 20px;
	color: #E8BE3B;
}

.error-banner__content {
	margin: 5px 0 0 0;

	font-size: 15px;
	color: #E1CA80;
}
p.form-error-message {
	padding: 5px;
	margin: 5px auto;

	background-color: var(--error-background);

	border-radius: 5px;

	color: var(--error-color);
	font-size: 13px;
	text-align: center;

	display: block;
}
/*  ---Banner & Message box --- */


/* Scrollbar */
/* width */
::-webkit-scrollbar {
	width: 13px;
	height: 13px;

	cursor: default;
}
/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {	
	background-color: rgba(0, 0, 0, .3);
	background-clip: padding-box;
	border: 4px solid rgba(0, 0, 0, 0);
	border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0, 0, 0, .5);
	background-clip: padding-box;
	border: 4px solid rgba(0, 0, 0, 0);
	border-radius: 10px;
}
/* --- Scrollbar --- */

/* Media queries */
@media screen and (min-width: 1251px) {
	nav {
		position: relative;
		visibility: visible;
	}
}

@media screen and (max-width: 1250px) {
	nav {
		left: 0;
		width: 35vw;

		position: fixed;
		visibility: hidden;
	}

	header .hamburgerMenu-icon {
		opacity: 1;
		visibility: visible;
	}
}
@media screen and (max-width: 1450px) {
	#asideBooking input[type="time"] {
		max-width: 75px;
	}
}
/* --- Media queries --- */
